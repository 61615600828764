import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound404 = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col items-center justify-center py-16 px-8 md:px-16 lg:px-32  md:py-32">
      <p className="text-4xl">{t("not_found_title")}</p>
      <Link to="/" className="text-xl mt-16 bg-web-green px-8 py-4 text-white">
        {t("not_found_btn")}
      </Link>
    </div>
  );
};

export default NotFound404;
