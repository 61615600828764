import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AiOutlineArrowRight } from "react-icons/ai";

import ImageAboutUs from "../images/img-about-us.png";
import ImageServices from "../images/img-services.png";
import ImageIndustry from "../images/img-industry.png";
import ImageCareers from "../images/img-careers.png";

import PageHeader from "../components/PageHeader";

import { GrCloudComputer } from "react-icons/gr";
import { SiMoneygram } from "react-icons/si";
import { RiOilFill } from "react-icons/ri";
import { MdRealEstateAgent } from "react-icons/md";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { MdEnergySavingsLeaf } from "react-icons/md";
import { RiGovernmentFill } from "react-icons/ri";
import { GiTeacher } from "react-icons/gi";
import { AiFillCar } from "react-icons/ai";
import { AiFillMedicineBox } from "react-icons/ai";
import { RiServiceFill } from "react-icons/ri";
import { RiMentalHealthFill } from "react-icons/ri";
import { AiFillShop } from "react-icons/ai";
import { MdOutlineTravelExplore } from "react-icons/md";
import { FaIndustry } from "react-icons/fa";
import { ImAirplane } from "react-icons/im";
import { SiTransportforlondon } from "react-icons/si";
import { MdEngineering } from "react-icons/md";

import Image1 from "../images/img-company-1.png";
import Image2 from "../images/img-company-2.png";
import Image3 from "../images/img-company-3.png";
import Image4 from "../images/img-company-4.png";
import Image5 from "../images/img-company-5.png";
import Image6 from "../images/img-company-6.png";
import Image7 from "../images/img-company-7.png";
import Image8 from "../images/img-company-8.png";
import Image9 from "../images/img-company-9.png";
import Image10 from "../images/img-company-10.png";

const images = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
];

const Industries = () => {
  const { t } = useTranslation();
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    // Duplicate the images and append them to the end of the list
    const duplicatedImages = [...images, ...images];
    containerRef.current.innerHTML = "";
    duplicatedImages.forEach((imageUrl) => {
      const image = document.createElement("img");
      image.src = imageUrl;
      image.className = "w-32 h-32 object-cover rounded-md shadow-md";
      containerRef.current.appendChild(image);
    });
  }, []);

  const handleAnimation = () => {
    const newScrollLeft = scrollLeft + 1;
    const maxScrollLeft = containerRef.current.scrollWidth / 2;
    if (newScrollLeft >= maxScrollLeft) {
      setScrollLeft(newScrollLeft - maxScrollLeft);
      containerRef.current.scrollTo({ left: 0 });
    } else {
      setScrollLeft(newScrollLeft);
    }
    animationRef.current = requestAnimationFrame(handleAnimation);
  };

  useEffect(() => {
    animationRef.current = requestAnimationFrame(handleAnimation);
    return () => cancelAnimationFrame(animationRef.current);
  }, [scrollLeft]);

  return (
    <div className="flex flex-col items-center">
      <PageHeader title="industry" image={ImageIndustry} />

      <p className="text-xl mt-32">{t("industry_subTitle1")}</p>
      <p className="text-web-blue text-4xl">{t("industry_title1")}</p>

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 px-8 mt-16">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <GrCloudComputer
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_0")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <SiMoneygram
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_1")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <RiOilFill
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_2")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <MdRealEstateAgent
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_3")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <TbDeviceLandlinePhone
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_4")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <MdEnergySavingsLeaf
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_5")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <RiGovernmentFill
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_6")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <GiTeacher
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_7")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <AiFillCar
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_8")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <AiFillMedicineBox
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_9")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <RiServiceFill
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_10")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <RiMentalHealthFill
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_11")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <AiFillShop
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_12")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <MdOutlineTravelExplore
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_13")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <FaIndustry
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_14")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <ImAirplane
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_15")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <SiTransportforlondon
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_16")}</p>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-col items-center text-center">
            <MdEngineering
              size={64}
              className="bg-web-green p-4 rounded-full bg-opacity-50"
            />
            <p className="mt-4 text-gray-600">{t("industry_17")}</p>
          </div>
        </div>
      </div>

      <p className="text-web-blue text-3xl mt-32">{t("industry_title2")}</p>
      <p className="text-xl text-center mx-8 md:mx-32">
        {t("industry_subTitle2")}
      </p>

      <div className="relative overflow-hidden mt-16">
        <div
          className="flex items-center space-x-4 p-4"
          style={{ transform: `translateX(-${scrollLeft}px)` }}
          ref={containerRef}
        >
          {images.map((image, index) => (
            <img
              key={index}
              className="w-32 h-32 object-cover rounded-md shadow-md"
              src={image}
              alt={`Image ${index}`}
            />
          ))}
        </div>
      </div>

      <p className="text-4xl text-web-blue mt-32">{t("discover_more")}</p>
      <div className="w-full mb-32 flex flex-col lg:flex-row justify-around">
        <Link className="mt-16 mx-16" to="/about-us">
          <div>
            <img src={ImageAboutUs} alt="about us"></img>
            <p className="text-xl mt-4">{t("discover_about")}</p>
            <div className="flex flex-row items-center mt-2 text-web-green">
              <p className="mr-4 text-lg">{t("read_more")}</p>
              <AiOutlineArrowRight />
            </div>
          </div>
        </Link>

        <Link className="mt-16 mx-16" to="/services">
          <div>
            <img src={ImageServices} alt="services"></img>
            <p className="text-xl mt-4">{t("discover_service")}</p>
            <div className="flex flex-row items-center mt-2 text-web-green">
              <p className="mr-4 text-lg">{t("read_more")}</p>
              <AiOutlineArrowRight />
            </div>
          </div>
        </Link>

        <Link className="mt-16 mx-16" to="/careers">
          <div>
            <img src={ImageCareers} alt="career"></img>
            <p className="text-xl mt-4">{t("discover_career")}</p>
            <div className="flex flex-row items-center mt-2 text-web-green">
              <p className="mr-4 text-lg">{t("read_more")}</p>
              <AiOutlineArrowRight />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Industries;
