import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";

import HomeContact from "../components/HomeContact";
import Slider from "../components/Slider";

import Image1 from "../images/img-company-1.png";
import Image2 from "../images/img-company-2.png";
import Image3 from "../images/img-company-3.png";
import Image4 from "../images/img-company-4.png";
import Image5 from "../images/img-company-5.png";
import Image6 from "../images/img-company-6.png";
import Image7 from "../images/img-company-7.png";
import Image8 from "../images/img-company-8.png";
import Image9 from "../images/img-company-9.png";
import Image10 from "../images/img-company-10.png";

const images = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
];

const Home = () => {
  const { t } = useTranslation();
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    // Duplicate the images and append them to the end of the list
    const duplicatedImages = [...images, ...images];
    containerRef.current.innerHTML = "";
    duplicatedImages.forEach((imageUrl) => {
      const image = document.createElement("img");
      image.src = imageUrl;
      image.className = "w-32 h-32 object-cover rounded-md shadow-md";
      containerRef.current.appendChild(image);
    });
  }, []);

  const handleAnimation = () => {
    const newScrollLeft = scrollLeft + 1;
    const maxScrollLeft = containerRef.current.scrollWidth / 2;
    if (newScrollLeft >= maxScrollLeft) {
      setScrollLeft(newScrollLeft - maxScrollLeft);
      containerRef.current.scrollTo({ left: 0 });
    } else {
      setScrollLeft(newScrollLeft);
    }
    animationRef.current = requestAnimationFrame(handleAnimation);
  };

  useEffect(() => {
    animationRef.current = requestAnimationFrame(handleAnimation);
    return () => cancelAnimationFrame(animationRef.current);
  }, [scrollLeft]);

  return (
    <div className="flex flex-col w-full">
      <Slider />

      <div className="w-full flex flex-col items-center text-center pt-16 md:pt-32 px-8 lg:px-32 sm:0x-16">
        <p className="text-4xl px-8 lg:px-0">{t("about_us")}</p>
        <p className="text-4xl text-web-blue">{t("about_us_home")}</p>
        <p className="text-lg mt-16">{t("about_title_home")}</p>
        <p className="text-lg mt-4">{t("about_para2")}</p>
        <Link
          to="/about-us"
          className="px-8 py-4 bg-web-green text-white mt-16"
        >
          {t("read_more2")}
        </Link>
      </div>

      <div className="w-full flex flex-col items-center text-center pt-16 md:pt-32 px-8 lg:px-32 sm:0x-16">
        <p className="text-4xl px-8 lg:px-0">{t("services")}</p>
        <p className="text-4xl text-web-blue">{t("services_sub2")}</p>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-16 px-8 md:px-32">
          <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
            {t("services_s1")}
          </div>
          <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
            {t("services_s2")}
          </div>
          <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
            {t("services_s3")}
          </div>
          <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
            {t("services_s4")}
          </div>
          <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
            {t("services_s5")}
          </div>
          <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
            {t("services_s6")}
          </div>
          <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
            {t("services_s7")}
          </div>
          <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
            {t("services_s8")}
          </div>
        </div>
        <Link
          to="/services"
          className="px-8 py-4 bg-web-green text-white mt-16"
        >
          {t("learn_more")}
        </Link>
      </div>

      <div className="w-full flex flex-col items-center text-center pt-16 md:pt-32 px-8 lg:px-32 sm:0x-16">
        <p className="text-4xl px-8 lg:px-0">{t("industry_title2")}</p>
        <p className="text-2xl text-web-blue">{t("industry_subTitle2")}</p>

        <div className="relative overflow-hidden mt-16">
          <div
            className="flex items-center space-x-4 p-4"
            style={{ transform: `translateX(-${scrollLeft}px)` }}
            ref={containerRef}
          >
            {images.map((image, index) => (
              <img
                key={index}
                className="w-32 h-32 object-cover rounded-md shadow-md"
                src={image}
                alt={`Image ${index}`}
              />
            ))}
          </div>
        </div>

        <Link
          to="/industries"
          className="px-8 py-4 bg-web-green text-white mt-16"
        >
          {t("learn_more")}
        </Link>
      </div>

      <HomeContact />
    </div>
  );
};

export default Home;
