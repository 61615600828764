import React from "react";
import ContactForm from "../components/ContactForm";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeContact = () => {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.dir() === "rtl"; // Check if the current language is right-to-left

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col justify-center lg:flex-row px-0 pt-16 md:pt-32">
        <div className="w-full lg:w-1/3 text-center lg:text-start">
          <p className="text-4xl px-8 lg:px-0">{t("contact_us_home1")}</p>
          <p className="text-4xl text-web-blue">{t("contact_us_home2")}</p>
        </div>
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0 ml-0 lg:ml-16">
          <ContactForm />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row justify-center items-center py-4 bg-web-green text-white text-center mt-16 md:mt-32">
        <p className="text-md md:text-lg">{t("contact_us_home3")}</p>
        <Link
          to="/contact-us"
          className="text-md md:text-lg w-fit px-2 py-1 border-2 mt-2 md:mt-0 ml-0 md:ml-4"
        >
          {t("contact_us")}
        </Link>
      </div>
    </div>
  );
};

export default HomeContact;
