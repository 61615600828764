import React from "react";

const SiderItem = ({ slide, isActive, slideDirection, indicators }) => {
  return (
    <div
      className={`absolute top-0 left-0 h-[30rem] w-full transform transition-transform duration-500 ease-in ${
        isActive
          ? "translate-x-0"
          : slideDirection === "left"
          ? "-translate-x-full"
          : "translate-x-full"
      }`}
    >
      <div className="grid grid-cols-2 h-full w-full">
        <div
          className={`p-8 text-start hidden md:flex flex-col justify-center items-start h-full w-full transition-opacity duration-1000 ease-in ${
            isActive ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex flex-row">{indicators}</div>
          <h1 className="text-2xl font-semibold mb-4 mt-4 lg:mt-8">
            {slide.title}
          </h1>
          <p className="text-xl text-web-grey">{slide.text}</p>
          <p className="mt-8 py-4 px-8 bg-web-green text-white">
            {slide.button}
          </p>
        </div>
        <div
          className={`col-span-2 md:col-span-1 h-full w-full flex flex-col items-center justify-center bg-cover bg-center transition-opacity duration-1000 ease-in ${
            isActive ? "opacity-100" : "opacity-0"
          }`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
          <div className="w-full h-full flex md:hidden flex-col justify-center items-center text-center p-4 text-white bg-black bg-opacity-50">
            <div className="flex flex-row">{indicators}</div>
            <h1 className="text-lg font-semibold mb-4 mt-8">{slide.title}</h1>
            <p className="text-md">{slide.text}</p>
            <p className="mt-2 py-2 px-4 bg-web-green">{slide.button}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiderItem;
