import React from "react";
import { Link } from "react-router-dom";

import i18n from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Logo from "../images/img-logo.png";
import { FiMenu } from "react-icons/fi";
import { GrClose } from "react-icons/gr";

const Navbar = () => {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageSwitch = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("lang", newLanguage);
    setIsOpen(false);
  };
  return (
    <div className="flex flex-col sticky top-0 bg-white z-30">
      <div
        className={`${
          i18n.language === "en" ? "flex-row" : "flex-row-reverse"
        } w-full py-4 px-8 flex justify-between items-center shadow-sm`}
      >
        <img className="h-9" src={Logo} alt="smart valley"></img>
        <div>
          <FiMenu
            className={`${
              isOpen ? "hidden" : "flex"
            } flex md:hidden text-web-blue cursor-pointer`}
            size={24}
            onClick={() => {
              toggleMenu();
            }}
          />
          <GrClose
            className={`${
              isOpen ? "flex" : "hidden"
            } flex md:hidden text-web-blue cursor-pointer`}
            size={24}
            onClick={() => {
              toggleMenu();
            }}
          />
        </div>

        <div
          className={`${
            i18n.language === "en" ? "flex-row" : "flex-row-reverse"
          } hidden md:flex justify-center items-center text-web-grey`}
        >
          <Link className="mx-2 lg:ml-12 hover:text-web-green" to="/">
            {t("home")}
          </Link>
          <Link className="mx-2 lg:ml-12 hover:text-web-green" to="/about-us">
            {t("about_us")}
          </Link>
          <Link className="mx-2 lg:ml-12 hover:text-web-green" to="/services">
            {t("services")}
          </Link>
          <Link className="mx-2 lg:ml-12 hover:text-web-green" to="/industries">
            {t("industry")}
          </Link>
          <Link className="mx-2 lg:ml-12 hover:text-web-green" to="/careers">
            {t("careers")}
          </Link>
          <Link
            className="mx-2 lg:ml-12 py-2 px-4 bg-web-green bg-opacity-25 text-black hover:text-white hover:bg-opacity-100"
            to="/contact-us"
          >
            {t("contact_us")}
          </Link>
          <p
            className="mx-2 lg:ml-16 text-web-blue text-md font-thin cursor-pointer"
            onClick={() => handleLanguageSwitch()}
          >
            {t("other_lang")}
          </p>
        </div>
      </div>

      <div
        className={`${
          isOpen ? "flex" : "hidden"
        } flex flex-col md:hidden w-full h-screen items-center`}
      >
        <p
          className="my-4 text-web-blue text-md font-thin cursor-pointer"
          onClick={() => handleLanguageSwitch()}
        >
          {t("other_lang")}
        </p>
        <Link
          className="my-4 w-fit hover:text-web-green"
          to="/"
          onClick={() => setIsOpen(false)}
        >
          {t("home")}
        </Link>
        <Link
          className="my-4 w-fit hover:text-web-green"
          to="/about-us"
          onClick={() => setIsOpen(false)}
        >
          {t("about_us")}
        </Link>
        <Link
          className="my-4 w-fit hover:text-web-green"
          to="/services"
          onClick={() => setIsOpen(false)}
        >
          {t("services")}
        </Link>
        <Link
          className="my-4 w-fit hover:text-web-green"
          to="/industries"
          onClick={() => setIsOpen(false)}
        >
          {t("industry")}
        </Link>
        <Link
          className="my-4 w-fit hover:text-web-green"
          to="/careers"
          onClick={() => setIsOpen(false)}
        >
          {t("careers")}
        </Link>
        <Link
          className="my-4 py-2 px-4 w-fit bg-web-green bg-opacity-25 text-black hover:text-white hover:bg-opacity-100"
          to="/contact-us"
          onClick={() => setIsOpen(false)}
        >
          {t("contact_us")}
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
