import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SliderItem from "../components/SiderItem";

import Slider1 from "../images/slider-1.png";
import Slider2 from "../images/slider-2.png";
import Slider3 from "../images/slider-3.png";
import Slider4 from "../images/slider-4.png";
import Slider5 from "../images/slider-5.png";

const Slider = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const intervalRef = useRef(null);

  const slides = [
    {
      id: 1,
      title: t("slider_title1"),
      image: Slider1,
      text: t("slider_text1"),
      button: <Link to="/about-us">{t("slider_btn1")}</Link>,
    },
    {
      id: 2,
      title: t("slider_title2"),
      image: Slider2,
      text: t("slider_text2"),
      button: <Link to="/contact-us">{t("slider_btn2")}</Link>,
    },
    {
      id: 3,
      title: t("slider_title3"),
      image: Slider3,
      text: t("slider_text3"),
      button: <Link to="/services">{t("slider_btn3")}</Link>,
    },
    {
      id: 4,
      title: t("slider_title4"),
      image: Slider4,
      text: t("slider_text4"),
      button: <Link to="/industries">{t("slider_btn4")}</Link>,
    },
    {
      id: 5,
      title: t("slider_title5"),
      image: Slider5,
      text: t("slider_text5"),
      button: <Link to="/careers">{t("slider_btn5")}</Link>,
    },
  ];

  const handleNextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleIndicatorClick = (index) => {
    setActiveIndex(index);
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(handleNextSlide, 5000);
  };

  useEffect(() => {
    intervalRef.current = setInterval(handleNextSlide, 5000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const indicators = slides.map((slide, index) => (
    <button
      key={index}
      className={`h-1 rounded-full mr-2 ${
        index === activeIndex ? "bg-web-green w-8" : "bg-gray-400 w-4"
      }`}
      onClick={() => handleIndicatorClick(index)}
    ></button>
  ));

  return (
    <div className="relative h-[30rem] w-screen overflow-hidden">
      {slides.map((slide, index) => (
        <SliderItem
          key={index}
          slide={slide}
          isActive={index === activeIndex}
          slideDirection={index > activeIndex ? "right" : "left"}
          indicators={indicators}
        />
      ))}
    </div>
  );
};

export default Slider;
