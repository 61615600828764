import React from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../components/PageHeader";

import ImageContactUs from "../images/img-contact.jpg";

import ContactForm from "../components/ContactForm";

import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { HiBuildingOffice2 } from "react-icons/hi2";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <PageHeader title="contact_us" image={ImageContactUs} />

      <p className="mx-8 text-center my-16 text-lg">
        {t("page_contact_title")}
      </p>

      <div className="w-full lg:w-1/2">
        <ContactForm />
      </div>

      <div className="w-full px-16 md:px-0 flex flex-col md:flex-row justify-around">
        <div className="bg-web-green w-full md:w-1/4 bg-opacity-25 mt-16 p-8 hover:text-white hover:bg-opacity-100">
          <FaPhoneAlt />
          <p className="mt-4">+966 56 185 8162</p>
          <p>+966 54 229 2569</p>
        </div>
        <div className="bg-web-green w-full md:w-1/4 bg-opacity-25 mt-16 p-8 hover:text-white hover:bg-opacity-100">
          <MdEmail />
          <p className="mt-4">info@smartvalley.sa</p>
        </div>
        <div className="bg-web-green w-full md:w-1/4 bg-opacity-25 mt-16 p-8 hover:text-white hover:bg-opacity-100">
          <HiBuildingOffice2 />
          <p className="mt-4">{t("address")}</p>
        </div>
      </div>

      <iframe
        title="Map Location"
        className="w-full border-0 mt-32"
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3622.1240925175607!2d46.76559931500142!3d24.791203984086675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ3JzI4LjMiTiA0NsKwNDYnMDQuMCJF!5e0!3m2!1sen!2sin!4v1682628449682!5m2!1sen!2sin"
        width="600"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default ContactUs;
