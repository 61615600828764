import React from "react";
import { Link } from "react-router-dom";

import { AiOutlineArrowRight } from "react-icons/ai";

import PageHeader from "../components/PageHeader";

import ImageServices from "../images/img-services.png";
import ImageAboutUs from "../images/img-about-us.png";
import ImageIndustry from "../images/img-industry.png";
import ImageCareers from "../images/img-careers.png";
import { useTranslation } from "react-i18next";
import ServicesItem from "../components/ServicesItem";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <PageHeader title="services" image={ImageServices} />

      <p className="mt-32 mx-8 md:mx-32 text-xl text-center">
        {t("services_sub1")}
      </p>
      <p className="mt-2 mx-8 md:mx-32 text-2xl text-center text-web-blue">
        {t("services_sub2")}
      </p>

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-16 px-8 md:px-32">
        <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
          {t("services_s1")}
        </div>
        <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
          {t("services_s2")}
        </div>
        <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
          {t("services_s3")}
        </div>
        <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
          {t("services_s4")}
        </div>
        <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
          {t("services_s5")}
        </div>
        <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
          {t("services_s6")}
        </div>
        <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
          {t("services_s7")}
        </div>
        <div className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center">
          {t("services_s8")}
        </div>
      </div>

      <p className="mt-32 text-web-blue text-4xl">{t("services_sub3")}</p>

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8 px-8">
        <ServicesItem title="services1_title" message="services1_message" />
        <ServicesItem title="services2_title" message="services2_message" />
        <ServicesItem title="services3_title" message="services3_message" />
        <ServicesItem title="services4_title" message="services4_message" />
        <ServicesItem title="services5_title" message="services5_message" />
        <ServicesItem title="services6_title" message="services6_message" />
        <ServicesItem title="services7_title" message="services7_message" />
        <ServicesItem title="services8_title" message="services8_message" />
        <ServicesItem title="services9_title" message="services9_message" />
        <ServicesItem title="services10_title" message="services10_message" />
        <ServicesItem title="services11_title" message="services11_message" />
      </div>

      <p className="text-4xl text-web-blue mt-32">{t("discover_more")}</p>
      <div className="w-full mb-32 flex flex-col lg:flex-row justify-around">
        <Link className="mt-16 mx-16" to="/about-us">
          <div>
            <img src={ImageAboutUs} alt="about us"></img>
            <p className="text-xl mt-4">{t("discover_about")}</p>
            <div className="flex flex-row items-center mt-2 text-web-green">
              <p className="mr-4 text-lg">{t("read_more")}</p>
              <AiOutlineArrowRight />
            </div>
          </div>
        </Link>

        <Link className="mt-16 mx-16" to="/industries">
          <div>
            <img src={ImageIndustry} alt="industry"></img>
            <p className="text-xl mt-4">{t("discover_industry")}</p>
            <div className="flex flex-row items-center mt-2 text-web-green">
              <p className="mr-4 text-lg">{t("read_more")}</p>
              <AiOutlineArrowRight />
            </div>
          </div>
        </Link>

        <Link className="mt-16 mx-16" to="/careers">
          <div>
            <img src={ImageCareers} alt="career"></img>
            <p className="text-xl mt-4">{t("discover_career")}</p>
            <div className="flex flex-row items-center mt-2 text-web-green">
              <p className="mr-4 text-lg">{t("read_more")}</p>
              <AiOutlineArrowRight />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Services;
