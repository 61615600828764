import React, { useState } from "react";
import PageHeader from "../components/PageHeader";
import ImageCareers from "../images/img-careers.png";
import { useTranslation } from "react-i18next";
import CareersItem from "../components/CareersItem";

const Careers = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState("");

  const showDialog = (title, message) => {
    setMessage(message);
    setIsOpen(true);
  };

  return (
    <div className="flex flex-col items-center">
      <PageHeader title="careers" image={ImageCareers} />

      <p className="mt-32 mx-8 md:mx-32 text-xl text-center">
        {t("careers_sub")}
      </p>
      <p className="mt-2 mx-8 md:mx-32 text-2xl text-center text-web-blue">
        {t("careers_title")}
      </p>

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-16 px-8 md:px-32">
        <CareersItem title="careers_1" message="careers1_message" />
        <CareersItem title="careers_2" message="careers2_message" />
        <CareersItem title="careers_3" message="careers3_message" />
        <CareersItem title="careers_4" message="careers4_message" />
        <CareersItem title="careers_5" message="careers5_message" />
        <CareersItem title="careers_6" message="careers6_message" />
        <CareersItem title="careers_7" message="careers7_message" />
        <CareersItem title="careers_8" message="careers8_message" />
        <CareersItem title="careers_9" message="careers9_message" />
      </div>

      <p className="mt-24 px-8 md:px-32 text-xl text-center">
        {t("careers_contact_text")}
      </p>
      <p className="mb-32 mt-2 px-8 md:px-32  text-2xl text-center text-web-blue">
        {t("careers_contact_email")}
      </p>
    </div>
  );
};

export default Careers;
