import React from "react";

import { useTranslation } from "react-i18next";

const PageHeader = (props) => {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.dir() === "rtl"; // Check if the current language is right-to-left

  return (
    <div
      className={`${
        isRTL ? "flex-row-reverse" : "flex-row"
      } relative md:flex items-center justify-center w-screen`}
    >
      <p className="hidden md:block w-full md:w-1/2 text-5xl text-center text-web-blue">
        {t(props.title)}
      </p>
      <div
        className={
          "flex flex-row items-center justify-center bg-cover w-screen h-[20rem] md:w-1/2"
        }
        style={{ backgroundImage: `url(${props.image})` }}
      >
        <div className="w-full h-full flex md:hidden flex-col justify-center items-center text-center p-4 bg-white bg-opacity-50">
          <p className="text-4xl text-web-blue">{t(props.title)}</p>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
