import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IoCloseCircle } from "react-icons/io5";

const CareersItem = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          <div className="flex flex-col bg-white pb-8 rounded-lg z-20 w-full mx-8 max-h-[80vh] mt-16 overflow-y-auto">
            <button
              className="w-fit bg-white text-web-green font-bold rounded self-end p-4"
              onClick={() => setIsOpen(false)}
            >
              <IoCloseCircle size={32} />
            </button>
            <p
              className="text-gray-800 whitespace-break-spaces px-8"
              dangerouslySetInnerHTML={{ __html: t(props.message) }}
            ></p>
            <p className="text-gray-800 whitespace-break-spaces px-8 mt-8">
              {t("careers_contact_text2")}
            </p>
            <p className="text-web-blue whitespace-break-spaces px-8">
              {t("careers_contact_email")}
            </p>
          </div>
        </div>
      )}

      <div
        className="bg-web-green bg-opacity-25 hover:bg-opacity-100 hover:text-white p-4 text-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        {t(props.title)}
      </div>
    </div>
  );
};

export default CareersItem;
