import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yfwq80a",
        "template_36d4lcp",
        form.current,
        "iU6EGMeBW4XvQIXo9"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="flex flex-col w-full px-8 sm:px-16 lg:px-0 items-center">
      <form
        ref={form}
        onSubmit={sendEmail}
        className="w-full flex flex-col shadow-md p-8"
      >
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 w-full flex flex-col">
            <label>{t("form_name")}</label>
            <input
              className="bg-web-grey bg-opacity-20 px-4 py-2 mt-2"
              type="text"
              name="user_name"
              placeholder={t("form_name_hint")}
            />
          </div>
          <div className="md:w-1/2 w-full flex flex-col mt-8 md:mt-0 ml-0 md:ml-8">
            <label>{t("form_organization")}</label>
            <input
              className="bg-web-grey bg-opacity-20 px-4 py-2 mt-2"
              type="text"
              name="user_organization"
              placeholder={t("form_organization_hint")}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row mt-8">
          <div className="md:w-1/2 w-full flex flex-col">
            <label>{t("form_email")}</label>
            <input
              className="bg-web-grey bg-opacity-20 px-4 py-2 mt-2"
              type="text"
              name="user_email"
              placeholder={t("form_email_hint")}
            />
          </div>
          <div className="md:w-1/2 w-full flex flex-col mt-8 md:mt-0 ml-0 md:ml-8">
            <label>{t("form_phone")}</label>
            <input
              className="bg-web-grey bg-opacity-20 px-4 py-2 mt-2"
              type="text"
              name="user_phone"
              placeholder={t("form_phone_hint")}
            />
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <label>{t("form_enquiry")}</label>
          <textarea
            className="bg-web-grey bg-opacity-20 px-4 py-2 mt-2 h-[15rem]"
            name="message"
            placeholder={t("form_enquiry_hint")}
          />
          <input
            className="cursor-pointer w-fit self-end text-white bg-web-green px-8 py-2 mt-8"
            type="submit"
            value={t("form_send")}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
