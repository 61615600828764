import React from "react";
import Logo from "../images/img-icon.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full text-white bg-web-blue py-8 flex flex-col justify-center items-center">
      <img className="h-16" src={Logo} alt="smart"></img>
      <p className="text-3xl font-semibold">{t("name")}</p>
      <p className="text-md opacity-50">{t("slogan")}</p>

      <div className="flex flex-col sm:flex-row justify-around items-start md:items-center mt-8 text-web-green">
        <Link className="hover:text-white mx-0 sm:mx-4" to="/">
          ● {t("home")}
        </Link>
        <Link className="hover:text-white mx-0 sm:mx-4" to="/about-us">
          ● {t("about_us")}
        </Link>
        <Link className="hover:text-white mx-0 sm:mx-4" to="/services">
          ● {t("services")}
        </Link>
        <Link className="hover:text-white mx-0 sm:mx-4" to="/industries">
          ● {t("industry")}
        </Link>
        <Link className="hover:text-white mx-0 sm:mx-4" to="/careers">
          ● {t("careers")}
        </Link>
        <Link className="hover:text-white mx-0 sm:mx-4" to="/contact-us">
          ● {t("contact_us")}
        </Link>
      </div>

      <p className="text-sm text-center opacity-50 mt-8">
        2023 © Smart Valley Corporation for Communications and Information
        Technology
      </p>
    </div>
  );
};

export default Footer;
