import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IoCloseCircle } from "react-icons/io5";

const ServicesItem = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          <div className="flex flex-col bg-white pb-8 rounded-lg z-20 w-full mx-8 max-h-[80vh] mt-16 overflow-y-auto">
            <button
              className="w-fit bg-white text-web-green font-bold rounded self-end p-4"
              onClick={() => setIsOpen(false)}
            >
              <IoCloseCircle size={32} />
            </button>
            <h2 className="text-2xl font-bold mb-4 px-8">{t(props.title)}</h2>
            <p className="text-gray-800 whitespace-break-spaces px-8">
              {t(props.message)}
            </p>
          </div>
        </div>
      )}

      <div className=" flex flex-col justify-between bg-white p-6 rounded-lg shadow-md">
        <div className="text-start mt-4">
          <h3 className="text-2xl font-medium text-gray-900">
            {t(props.title)}
          </h3>
          <p className="mt-2 text-gray-600 overflow-ellipsis line-clamp-6 whitespace-break-spaces">
            {t(props.message)}
          </p>
        </div>
        <p
          className="bg-web-green w-fit px-4 py-2 mt-8 text-white cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          {t("learn_more")}
        </p>
      </div>
    </div>
  );
};

export default ServicesItem;
